.mobile .tracks-collector-zero {
  padding: 10px;
  margin-bottom: 0;
}

.mobile .tracks-collector-zero .tracks-collector-zero-title {
  text-align: center;
  font-size: 8vw;
  line-height: 1;
  margin-top: 30px;
}

.mobile .tracks-collector-zero .tracks-collector-zero-desc {
  text-align: center;
  font-size: 4.5vw;
  line-height: 1;
  padding: 0 20px;
  margin-bottom: 20px;
}

.mobile .tracks-collector-zero-images p {
  text-align: center;
  font-size: 3.5vw;
  line-height: 1;
  width: 90vw;
}

.mobile .tracks-collector-zero .zero-image-1 svg:first-of-type {
  width: 90vw;
}

.mobile .tracks-collector-zero .tracks-collector-zero-images svg:last-of-type {
  margin-top: 10px;
}

.mobile .tracks-collector-zero .zero-image-2 svg:first-of-type {
  width: 90vw;
}

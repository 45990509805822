.mobile .genre-explorer {
  padding: 10px 20px;
}

.mobile .genre-explorer .year-label {
  font-size: 8vw;
}

.mobile .genre-explorer .album-tile {
  margin: 0 30px 30px 0;
}

.landing {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #306ebf;
}

.landing .landing-captain {
  flex: 1;
  height: 100%;
  position: relative;
}

.landing .landing-captain img {
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.landing .landing-content {
  flex: 1;
  height: 100%;
  padding-left: 50px;
  padding-top: 50px;

  color: #ffffff;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.landing .app-logo {
  font-size: 50px;
}

.landing .landing-slogan {
  margin-top: 12vh;
  font-size: 3.2vw;
  line-height: 1.2;
  font-weight: bold;
}

@media screen and (min-width: 1600px) {
  .landing .landing-slogan {
    width: 80%;
  }

  .landing .landing-description {
    width: 80%;
    margin-top: 30px;
  }

  .landing .landing-login {
    margin-top: 35px !important;
  }
}

@media screen and (min-aspect-ratio: 1440/660) {
  :not(.mobile) .landing .landing-legal {
    display: none;
  }
}

@media screen and (min-aspect-ratio: 1440/600) {
  :not(.mobile) .landing .landing-description {
    display: none;
  }
  :not(.mobile) .landing .landing-legal {
    display: block;
  }
}

@media screen and (min-aspect-ratio: 1440/500) {
  :not(.mobile) .landing .landing-legal {
    display: none;
  }
}

.landing .landing-description {
  width: 85%;
  font-size: 1.1vw;
  line-height: 1.5;
}

.landing .landing-slogan span,
.landing .landing-description span {
  white-space: nowrap;
}

.landing .landing-description button {
  display: inline-block;
  padding: 5px 0;
  border: none;
  border-bottom: 1px solid white;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  background: none;
  color: white;
}

.landing .landing-description button:hover {
  color: #ee445e;
  border-bottom: 1px solid #ee445e;
}

.landing-features-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  background-color: white;
  color: #231f20;
  padding: 25px 30px;
  border-radius: 30px;
  width: auto !important;
}

.landing-features-content .landing-features {
  display: flex;
  flex-direction: row;
}

.landing-features-content .landing-features .landing-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  padding: 10px 30px;
}

.landing-features-content .landing-features .landing-feature img {
  width: 100px;
}

.landing-features-content .landing-features .landing-feature span {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: 15px 0 0 0;
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
}

.landing-features-content .landing-features .landing-feature p {
  font-size: 14px;
  text-align: center;
}

.landing-features-content .landing-features-back {
  margin-top: 10px;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.landing-features-content .landing-features-back:hover {
  opacity: 0.8;
}

.landing .landing-login {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  box-sizing: border-box;
  z-index: 2;
}

.landing .landing-button,
.landing-features-content .landing-features-back {
  background-color: #ee445e;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  height: 50px;
  padding: 0 30px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.landing-features-content .landing-features-back {
  margin-right: 0;
}


.landing .landing-button:hover {
  background-color: white;
  color: #ee445e;
}

.landing .social-links {
  margin-bottom: 5px;
}

.landing .landing-legal {
  position: absolute;
  bottom: 20px;
  font-size: 12px;
}

.landing .landing-why-login button {
  margin-top: 5px;
  margin-left: 20px;
  display: inline-block;
  padding: 5px 0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  background: none;
  color: white;
}

.landing .landing-why-login button:hover {
  color: #ee445e;
  border-bottom: 1px solid #ee445e;
}
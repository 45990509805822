.app-logo-wrapper {
    display: flex;
    align-items: center;
}

.app-logo-wrapper img {
    border-radius: 50%;
}

.app-logo {
    color: white;
    font-size: 32px;
    font-weight: bold;
    padding-left: 15px;
    letter-spacing: 3px;
}
.playlists-menu-button {
  display: inline-block;
  padding: 5px 15px;
  color: white;
  font-size: 15px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.playlists-menu-button:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.playlists-menu-button .icon-arrow {
  margin-top: -4px;
  margin-right: 8px;
}

.playlists-menu-button .playlists-menu-name {
  margin-right: 10px;
  font-weight: bold;
}

.playlists-menu {
  z-index: 10000;
  font-size: 16px;
}

.playlists-menu a {
  color: white;
  text-decoration: none;
}

.playlists-menu .icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-size: cover;
}

.mobile .artist-details {
  padding: 0;
}

.mobile .artist-details .artist-cover {
  position: relative;
}

.mobile .artist-details .artist-cover .artist-image {
  width: 100vw;
  height: 100vw;
  background-position: center center;
  background-size: cover;
  opacity: 0.5;
}

.mobile .artist-details .artist-cover .artist-info {
  position: absolute;
  bottom: 40px;
  width: 100%;
  margin-left: 0;
  text-align: center;
}

.mobile .artist-details .artist-cover .artist-info .artist-name {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  font-size: 10vw;
  line-height: 1;
}

.mobile .artist-details .artist-cover .artist-info .artist-genres {
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding: 0 20px;
  color: white;
}

.mobile .artist-details .artist-cover .artist-menu .rc-menu-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 36px;
}

.mobile .artist-details .artist-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
}

.mobile .artist-details .artist-stats .play-button {
  position: relative;
  top: -30px;
  width: 80px;
  margin: 0;
  opacity: 0.9;
}

.mobile .artist-details .artist-stats .play-button:active {
  opacity: 1;
}

.mobile .artist-details .artist-stats .artist-stat .stat-name {
  margin-top: 15px;
  font-size: 3vw;
  text-align: right;
  word-break: break-word;
}

.mobile .artist-details .artist-stats .artist-stat .stat-value {
  font-size: 4.5vw;
}

.mobile .artist-details .tab-menu {
  z-index: 10000;
  font-size: 16px;
}

.mobile .artist-details .tab-menu-button + .rc-menu-container {
  display: inline-block;
}

.mobile .artist-details .tab-menu-button.rc-menu-button {
  display: inline-block;
  margin: 0 15px 0 20px;
  padding: 0;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mobile .artist-details .tab-menu-button.rc-menu-button img {
  margin-left: 4px;
}

.mobile .artist-details .artist-tab-content {
  margin: 10px 20px;
}

.mobile .artist-details .artist-details-mobile-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile .artist-details .artist-details-mobile-actions .album-mix-menu-button,
.mobile .artist-details .artist-details-mobile-actions .artist-mix-menu-button {
  margin-top: 0;
  margin-right: 20px;
}

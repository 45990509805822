.app-footer {
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #036551;
  border-top: 1px solid #181818;
  transition: opacity 0.2s ease;
}

.app-footer-hidden {
  opacity: 0;
}

.app-footer .social-links {
  margin-top: 4px;
  margin-right: 20px;
}

.app-footer .app-footer-legal {
  padding: 10px;
  color: white;
  font-size: 13px;
}

.mobile .app-footer .app-footer-legal {
  font-size: 2vw;
}

.mobile .app-footer .app-footer-social {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
}

.artist-mix-menu-button {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  color: white;
  font-size: 15px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.artist-mix-menu-button:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.artist-mix-menu-button img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.artist-mix-menu {
  z-index: 10000;
  font-size: 16px;
}

.label-explorer {
  height: 100%;
  padding: 10px 30px 40px;
  overflow-y: auto;
}

.label-explorer .selection-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.label-explorer .selection-options .label-search-button {
  margin-left: 20px;
  display: inline-block;
  padding: 10px 15px;
  color: white;
  font-size: 16px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.label-explorer .scroll-container {
  display: flex;
  flex-flow: wrap;
  margin-top: 30px;
}

.label-explorer .album-tile {
  margin-right: 30px;
  margin-bottom: 30px;
}


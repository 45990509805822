.mode-menu-button + .rc-menu-container {
  display: inline-block;
}

.mode-menu-button.rc-menu-button {
  background-color: transparent;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 0 0;
}

.mode-menu-button.rc-menu-button img {
  margin-left: 4px;
}

.mobile .mode-menu-button.rc-menu-button {
  font-size: 3vw;
}

.rc-menu__item--type-checkbox.rc-menu__item--checked::before {
  content: url(data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.1757%207.73716L6.52227%2013.8362L2.34921%2010.2593L3.6508%208.74075L6.47774%2011.1638L11.8243%206.26285L13.1757%207.73716Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A) !important;
}

.rc-menu__item > svg {
  margin-right: 8px;
}

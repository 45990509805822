.popup-content.album-selection-options-popup-content {
  width: 270px !important;
  border-radius: 10px;
  padding: 20px;
  background-color: #22262c;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001 !important;
  border: 1px solid #333333;
}

.album-selection-options {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 20px;
  top: 90px;
  z-index: 1000;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.album-selection-options-large-screen {
  top: 20px;
}

.album-selection-options .album-selection-options-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  top: 7px;
}

.album-selection-options .album-selection-options-button .album-selection-custom-options-indicator {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: rgb(243 20 20 / 0.8);
  position: absolute;
  top: -12px;
  right: -8px;
}

.popup-content.album-selection-options-popup-content .option {
  display: flex;
  flex-direction: row;
  width: 260px;
  margin-bottom: 20px;
}

.popup-content.album-selection-options-popup-content .option .label {
  width: 220px;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.popup-content.album-selection-options-popup-content .option.remember-preferences {
  border-top: 1px solid #424242;
  padding-top: 10px;
  margin-top: 5px;
}

.popup-content.album-selection-options-popup-content button {
  background-color: #ee445e;
  border: none;
  font-size: 16px;
  color: white;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.popup-content.album-selection-options-popup-content button.disabled {
  pointer-events: none;
  background-color: #b8b8b8;
  color: grey;
}

.popup-content.album-selection-options-popup-content .year-range-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  width: 250px;
}

.popup-content.album-selection-options-popup-content .option.exact-match {
  margin-bottom: 10px;
}

.popup-content.album-selection-options-popup-content .year-range-selector .year-select {
  width: 48%;
}

.year-select__menu-portal {
  z-index: 1002 !important;
}

.year-select__option {
  font-size: 14px;
  font-weight: normal;
}

.popup-arrow.album-selection-options-popup-arrow {
  color: #22262c;
}

.mobile .bridge {
  padding: 30px 0;
}

.mobile .bridge .bridge-greeting {
  font-size: 3vw;
}

.mobile .bridge .bridge-greeting2 {
  font-size: 3.5vh;
}

.mobile .bridge .bridge-section-more {
  font-size: 3.2vw;
  font-weight: bold;
}

.mobile .bridge .bridge-section-more:hover .bridge-section-more:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.mobile .bridge-section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile .bridge .bridge-section h2 {
  font-size: 3.2vh;
}

.mobile .bridge .bridge-greeting,
.mobile .bridge .bridge-greeting2,
.mobile .bridge .bridge-section-title,
.mobile .bridge .bridge-section-buttons,
.mobile .bridge .bridge-genres-list,
.mobile .bridge .bridge-labels-list
{
  padding-right: 10px;
  padding-left: 20px;
}

.mobile .bridge .bridge-greeting,
.mobile .bridge .bridge-greeting2 {
  padding-right: 20px;
}

.mobile .bridge .artist-tile:first-of-type,
.mobile .bridge .album-tile:first-of-type {
  padding-left: 20px;
}

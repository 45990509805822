.tracklist {
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  grid-template-columns: 50px fit-content(20%) fit-content(50%) 50px 100px 20px 20px;
  margin-top: 30px;
}

.mobile .tracklist {
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 30px 50px 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.artist-tile {
  width: 130px;
  font-size: 14px;
}

.artist-tile .artist-image {
  box-sizing: border-box;
  width: 130px;
  height: 130px;
  background-position: center;
  background-size: 100%;
  border-radius: 50%;
  transition: background-size 0.5s ease-in-out;
}

.artist-tile .artist-image .no-cover {
  border: 1px solid hsl(0deg 0% 100% / 0.1);
}

.artist-tile .artist-name {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  box-sizing: border-box;
  width: 130px;
  margin-top: 10px;
  padding: 0 10px;
  overflow: hidden;
  color: white;
  font-size: 13px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.artist-tile .rc-menu-container {
  display: inline-block;
}

.artist-tile .rc-menu-button {
  display: inline-block;
  padding: 0;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.rc-menu-button:hover .artist-image {
  background-size: 105%;
}

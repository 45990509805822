.album-menu {
  z-index: 10000;
  font-size: 16px;
}

.rc-menu-button.album-menu-button {
  display: inline-block;
  padding: 0;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

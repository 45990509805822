.album-tile {
  width: 130px;
  font-size: 14px;
}

.album-tile .album-image {
  box-sizing: border-box;
  width: 130px;
  height: 130px;
  background-position: center;
  background-size: 100%;
  transition: background-size 0.5s ease-in-out;
}

.album .album-image.no-cover {
  border: 1px solid hsl(0deg 0% 100% / 0.1);
}

.album-tile .album-artist {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  margin: 5px 0 0;
  padding: 0 5px;
  overflow: hidden;
  color: rgb(255 255 255 / 0.7);
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.album-tile .album-name {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  margin: 10px 0 0;
  padding: 0 5px;
  overflow: hidden;
  color: white;
  font-size: 13px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.album-tile .album-label {
  display: -webkit-box;
  margin-top: 7px;
  font-size: 11px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.rc-menu-button:hover .album-image {
  background-size: 105%;
  position: relative;
}

.album-tile .album-type {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  margin-right: 4px;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  left: 2px;
  border-radius: 50%;
}

.album-tile .album-type span {
  font-size: 10px;
  position: relative;
  top: -2px;
}
.spotify-explorer {
  width: 100%;
  height: 100%;
  position: fixed;
}

.spotify-explorer .flowchart-node {
  border: 0 !important;
  padding: 5px !important;
  width: 200px !important;
  border: none !important;
  cursor: default !important;
  pointer-events: auto !important;
}

.landing.mobile {
  flex-direction: column;
}

.landing.mobile .landing-captain img {
  height: auto;
}

.landing.mobile .landing-content {
  padding: 30px;
}

.landing.mobile .app-logo {
  font-size: 32px;
}

.landing.mobile .landing-slogan {
  margin-top: 20px;
  font-size: 4vh;
}

.mobile.ru .landing-slogan {
  font-size: 3vh;
}

.landing.mobile .landing-description {
  width: 95%;
  font-size: 1.8vh;
}

.landing.mobile .landing-description button {
  padding: 14px 20px 10px;
  color: #306ebf;
  font-size: 1.8vh;
  background-color: white;
  border: 1px solid #306ebf;
  border-radius: 30px;
}

.landing.mobile .landing-login {
  width: auto;
  height: 45px;
  font-size: 14px;
  letter-spacing: 2px;
}

.landing.mobile .landing-legal {
  bottom: 10px;
  padding-right: 20px;
}

.mobile .landing-features-content {
  box-sizing: border-box;
  width: 90vw !important;
  height: 80vh;
  overflow-y: scroll;
}

.mobile .landing-features-back {
  height: 40px;
  letter-spacing: 2px;
}

.mobile .landing-features-content .landing-features {
  display: flex;
  flex-direction: column;
}

.landing.mobile .landing-button,
.landing.mobile .landing-features-content .landing-features-back {
  font-size: 1.3vh;
}

.mobile.ru .landing-button {
  font-size: 1.2vh;
}

.landing.mobile .landing-why-login button {
  padding: 7px 10px 5px;
  color: #306ebf;
  font-size: 1.4vh;
  background-color: white;
  border: 1px solid #306ebf;
  border-radius: 30px;
  margin-top: 15px;
}

.mobile.ru .landing-description button {
  font-size: 1.5vh;
}

.app-container.mobile .app-options {
  margin-left: 60px;
  display: flex;
  align-items: center;
}

.app-container.mobile .app-header {
  padding: 0;
  height: 60px;
}

.app-container.mobile .app-body {
  padding-top: 60px;
}

.mobile .section-title {
  font-size: 5vw;
  color: white;
  margin-left: 5px;
}

.app-container.mobile .loading-logo .app-logo {
  font-size: 24px;
}

.app-container.mobile .loading-logo {
  margin-left: -100px;
  margin-top: -25px;
}
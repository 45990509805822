.tracks-collector-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.tracks-collector-badge:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.tracks-collector-badge .tracks-collector-badge-counter {
  margin-right: 5px;
  color: white;
  font-size: 18px;
}

.tracks-collector-badge img {
  width: 22px;
  height: 22px;
  fill: white;
}

.mobile .tracks-collector-badge .tracks-collector-badge-counter {
  font-size: 5vw;
}

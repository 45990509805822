.sliding-row-container {
  display: flex;
  flex-direction: row;
}

.sliding-row-container .sliding-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
}

.sliding-row-container button {
  cursor: pointer;
  background-color: transparent;
  border: transparent;
  border-radius: 5px;
}

.sliding-row-container button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sliding-row-container .slide-left {
  margin-right: 10px;
}

.sliding-row-container .slide-right {
  margin-left: 10px;
}

.mobile .sliding-row-container .sliding-row {
  overflow-x: auto;
}

.mobile .sliding-row-container .slide-left,
.mobile .sliding-row-container .slide-right {
  display: none;
}

body,
html {
  background-color: #181818;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

body.full-height {
  height: 100%;
}

body.no-scroll {
  overflow-y: hidden;
}

div.app-container {
  display: flex;
  flex-direction: column;
}

.app-header {
  opacity: 1;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  position: fixed;
  background-color: #036551;
  width: 100%;
  z-index: 999;
  box-sizing: border-box;
  transition: opacity 0.2s ease;
}

.app-header-hidden {
  opacity: 0;
}

.app-options {
  flex-grow: 1;
}

.app-body {
  padding-top: 70px;
}

.rc-menu-container {
  z-index: 10000;
}

.loading-logo {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -25px;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  10% {
    opacity: 0.6;
  }

  20% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.8;
  }

  40% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0.9;
  }

  70% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.7;
  }

  90% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.5;
  }
}

.Toastify__toast--success {
  background-color: #036551;
}

.rc-menu-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
}

.artist-browser {
  margin-top: 40px;
  margin-bottom: 50px;
}

.artist-browser input {
  margin-right: 20px;
}

.artist-browser .selection-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.artist-browser .selection-options .selection-label {
  margin-left: 10px;
  color: white;
  font-size: 16px;
}

.artist-browser .artist-suggestions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.scroll-container {
  display: flex;
  flex-flow: wrap;
}

.artist-browser .artist-suggestions .artist-tile {
  margin: 20px;
}

.artist-browser .followed-only {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile .artist-browser {
  margin-top: 20px;
}

.mobile .artist-browser .selection-options {
  flex-direction: column;
}

.mobile .artist-browser input {
  margin-right: 0;
}

.mobile .artist-browser .followed-only {
  margin-top: 10px;
}

.mobile .artist-browser .artist-suggestions {
  margin-top: 20px;
}

.mobile .artist-browser .selection-options .selection-label {
  margin-top: 2px;
  font-size: 5vw;
}

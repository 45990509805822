.social-links {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.social-links a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 4px;
  border-radius: 50%;
}

.social-links a:not(:last-of-type) {
  margin-right: 10px;
}

.social-links a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

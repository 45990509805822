.tracklist-entry-number {
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  font-weight: lighter;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tracklist-cover-art-image {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
  background-size: cover;
}

.tracklist-entry-name {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tracklist-entry-name:active {
  color: #036551;
}

.tracklist-entry-artists {
  color: white;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);

  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.tracklist-entry-duration {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tracklist-entry-popularity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracklist-entry-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracklist-entry-menu .tracklist-entry-menu-stub {
  font-size: 16px;
  color: white;
}

.tracklist-entry-play.play-button {
  width: 20px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracklist-entry-play.play-button img {
  width: 20px;
  height: 20px;
}

.tracklist-entry-play.play-anima {
  cursor: default;
}

.tracklist-entry-play.play-anima img {
  width: 20px;
}

.tracklist-entry-play.play-button:active {
  opacity: 0.5;
  width: 20px !important;
  margin: 0 !important;
}

.mobile .tracklist-entry-mobile-row {
  display: flex;
  align-items: center;
}

.mobile .tracklist-entry-artists {
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

.mobile .tracklist-entry-duration {
  font-size: 11px;
}

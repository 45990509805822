/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 22px;
  left: 16px;
  top: 19px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px;
  width: 50px;
  z-index: 1000;
  color: white;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  overflow-y: hidden;
}

/* General sidebar styles */
.bm-menu {
  background-color: rgba(24, 24, 24, 0.95);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 7px 10px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.mobile .bm-menu {
  padding: 0;
}

.mobile .bm-burger-button {
  top: 20px;
}

.mobile .label-explorer {
  padding: 10px 20px;
}

.mobile .label-explorer .selection-options {
  margin-top: 10px;
  justify-content: left;
}

.mobile .label-explorer .selection-options .suggest-input {
  width: 70%;
}

.mobile .label-explorer .label-search-button {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.mobile .label-explorer .label-search-button img {
  width: 28px;
  height: 28px;
}

.mobile .label-explorer .album-tile {
  margin: 0 30px 30px 0;
}
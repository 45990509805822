html {
  height: 100%;
}

body {
  background-attachment: fixed;
  min-height: 100%;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #181818;
}

body::-webkit-scrollbar-thumb {
  background-color: #036551;
}

body.shadow-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-blend-mode: darken;
  background-color: rgb(0 0 0 / 0.95);
  background-attachment: fixed;
}

#root {
  height: 100%;
  width: 100%;
}

.user-menu-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.user-menu-button .icon-arrow {
  margin-top: -4px;
  margin-right: 8px;
}

.user-menu-button .user-menu-name {
  margin-right: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.user-menu-button .user-menu-avatar {
  width: 40px;
  border-radius: 50%;
}

.user-menu {
  z-index: 10000;
  font-size: 16px;
}

.user-menu a {
  color: white;
  text-decoration: none;
}

.user-menu img.icon {
  height: 30px;
  margin-top: -2px;
  margin-right: 5px;
}

.mobile .user-menu-button {
  margin-right: 10px;
}

.mobile .user-menu-button .user-menu-avatar {
  width: 30px;
}

.albums-list-items {
  display: flex;
  flex-flow: wrap;
}

.albums-list-items .album-tile,
.albums-list-items .album-mix-tile {
  margin-bottom: 20px;
  margin-right: 30px;
}

.albums-list .albums-list-sorting-menu {
  margin-bottom: 10px;
}
